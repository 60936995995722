<template>
  <div class="md:max-w-7xl w-full mx-auto top-0 p-4">
    <div>
      <h1 class="p-2 text-2xl bg-brand text-white rounded-md mb-2">
        {{ mentor_types[0] ? mentor_types[0].name : "" }} Mentor
      </h1>
      <div class="grid md:grid-cols-12 gap-4">
        <div
          class="md:col-span-3 bg-white shadow-md p-2 rounded-md"
          v-for="mentor_list in mentor_lists[
            mentor_types[0] ? mentor_types[0].id : ''
          ]"
          :key="mentor_list.id"
        >
          <div
            class="w-24 h-24 border border-green-500 rounded-full p-1 mx-auto" v-if="mentor_list.gender == 2"
          >
            <img
              alt="Vue logo"
              src="../../../assets/image/female.jpg"
              class="text-green-500 rounded-full"
            />
          </div>
          <div
            class="w-24 h-24 border border-green-500 rounded-full p-1 mx-auto" v-if="mentor_list.gender == 1"
          >
            <img
              alt="Vue logo"
              src="../../../assets/image/user.jpg"
              class="text-green-500 rounded-full"
            />
          </div>
          <div>{{ mentor_list.name || "Empty" }}</div>
          <div class="text-brand">{{ mentor_types[0] ? mentor_types[0].name : ""  || "Empty" }}</div>
          <!-- <hr class="w-full border" /> -->
          <div class="text-sm"><span v-html="mentor_list.medical"></span></div>
          <div class="text-sm">
            <span v-html="mentor_list.designation"></span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h1 class="p-2 text-2xl bg-brand text-white rounded-md mt-2 mb-2">
        {{ mentor_types[1] ? mentor_types[1].name : "" }} Mentor
      </h1>
      <div class="grid md:grid-cols-12 gap-4">
        <div
          class="md:col-span-3 bg-white shadow-md p-2 rounded-md"
          v-for="mentor_list in mentor_lists[
            mentor_types[1] ? mentor_types[1].id : ''
          ]"
          :key="mentor_list.id"
        >
          <div
            class="w-24 h-24 border border-green-500 rounded-full p-1 mx-auto"
          >
            <img alt="Vue logo" src="../../../assets/image/user.jpg" class="rounded-full" />
          </div>
          <div>{{ mentor_list.name || "Empty" }}</div>
          <div class="text-brand">{{ mentor_types[1] ? mentor_types[1].name : ""  || "Empty" }}</div>
          <div><span v-html="mentor_list.medical"></span></div>
          <div><span v-html="mentor_list.designation"></span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // open:false
      seen: false,
      showModal: false,
    };
  },

  created() {
    this.$store.dispatch("mentors/getMentor");
  },

  computed: {
    mentor_types() {
      return this.$store.state.mentors.mentor_list.support_types || "";
    },
    mentor_lists() {
      return this.$store.state.mentors.mentor_list.mentor || "";
    },
  },
};
</script>
